import {
  createRouter,
  createWebHistory
} from "vue-router";

const routes = [{
    path: "/",
    redirect: "/home", //重定向
  },
  {
    path: "/layout",
    component: () => import( /* 控制台 */ "../views/layout/Layout.vue"),
    redirect: "/home",
    children: [{
      path: "/container",
      component: () => import( /*大容器 */ "../views/layout/Container.vue"),
      redirect: "/home",
      children: [{
          path: "/home",
          component: () => import( /*首页 */ "../views/home/Home.vue"),
          meta: {
            title: "首页",
          },
        },
        {
          path: "/searchresult",
          component: () => import( /*搜索结果 */ "../views/search/searchResult.vue"),
          meta: {
            title: "搜索结果",
          },
        },
        {
          path: "/news",
          component: () => import( /*公司建设 */ "../views/news/News.vue"),
          meta: {
            title: "公司建设",
          },
        },
        {
          path: "/news",
          component: () =>
            import(
              /*RouterView容器 */
              "../views/layout/ContainerRouterView.vue"
            ),
          meta: {
            title: "公司建设",
          },
          children: [{
              path: "/news/company",
              component: () =>
                import( /*公司新闻 */ "../views/news/Company.vue"),
              meta: {
                title: "公司新闻",
              },
            },
            {
              path: "/news/company/details",
              component: () =>
                import( /*公司新闻详情 */ "../views/news/CompanyDetails.vue"),
              meta: {
                title: "公司新闻详情",
              },
            },
            {
              path: "/news/publish",
              component: () =>
                import( /*通知公告 */ "../views/news/Publish.vue"),
              meta: {
                title: "通知公告",
              },
            },
            {
              path: "/news/publish/details",
              component: () =>
                import( /*通知公告详情 */ "../views/news/PublishDetails.vue"),
              meta: {
                title: "通知公告详情",
              },
            },{
              path: "/party",
              component: () =>
                import( /*党风建设 */ "../views/party/Party.vue"),
              meta: {
                title: "党风建设",
              },
            },
            {
              path: "/party/details",
              component: () =>
                import( /*党风建设详情 */ "../views/party/PartyDetails.vue"),
              meta: {
                title: "党风建设详情",
              },
            },
            
          ],
        },

        // {
        //   path: "/activity",
        //   component: () =>
        //     import( /*员工风采 */ "../views/activity/Activity.vue"),
        //   meta: {
        //     title: "员工风采",
        //   },
        // },
        // {
        //   path: "/activity", 
        //   component: () =>
        //     import(
        //       /*RouterView容器 */
        //       "../views/layout/ContainerRouterView.vue"
        //     ),
        //   meta: {
        //     title: "员工风采",
        //   },
        //   children: [{
        //     path: "/activity/details",
        //     component: () =>
        //       import(
        //         /*员工风采详情 */
        //         "../views/activity/ActivityDetails.vue"
        //       ),
        //     meta: {
        //       title: "员工风采详情",
        //     },
        //   }, ],
        // },

        // {
        //   path: "/party",
        //   component: () => import( /*党风建设 */ "../views/party/Party.vue"),
        //   meta: {
        //     title: "党风建设",
        //   },
        // },

        // {
        //   path: "/party",
        //   component: () =>
        //     import(
        //       /*RouterView容器 */
        //       "../views/layout/ContainerRouterView.vue"
        //     ),
        //   meta: {
        //     title: "党风建设",
        //   },
        //   children: [{
        //     path: "/party/details",
        //     component: () =>
        //       import( /*党风建设详情 */ "../views/party/PartyDetails.vue"),
        //     meta: {
        //       title: "党风建设详情",
        //     },
        //   }, ],
        // },

        {
          path: "/rock",
          component: () => import( /*他山之石 */ "../views/rock/Rock.vue"),
          meta: {
            title: "他山之石",
          },
        },

        {
          path: "/rock",
          component: () =>
            import(
              /*RouterView容器 */
              "../views/layout/ContainerRouterView.vue"
            ),
          meta: {
            title: "他山之石",
          },
          children: [{
            path: "/rock/details",
            component: () =>
              import( /*他山之石详情 */ "../views/rock/RockDetails.vue"),
            meta: {
              title: "他山之石详情",
            },
          }, ],
        },

        {
          path: "/tech",
          component: () => import( /*技术前瞻 */ "../views/tech/Tech.vue"),
          meta: {
            title: "技术前瞻",
          },
        },

        {
          path: "/tech",
          component: () =>
            import(
              /*RouterView容器 */
              "../views/layout/ContainerRouterView.vue"
            ),
          meta: {
            title: "技术前瞻",
          },
          children: [{
            path: "/tech/details",
            component: () =>
              import( /*技术前瞻详情 */ "../views/tech/TechDetails.vue"),
            meta: {
              title: "技术前瞻详情",
            },
          }, ],
        },

        {
          path: "/about",
          component: () => import( /*关于我们 */ "../views/about/About.vue"),
          meta: {
            title: "关于我们",
          },
          // children: [{
          //     path: "/about/contact",
          //     component: () =>
          //       import( /*联系我们 */ "../views/about/Contact.vue"),
          //     meta: {
          //       title: "联系我们",
          //     },
          //   },
          //   {
          //     path: "/about/introduce",
          //     component: () =>
          //       import( /*单位简介 */ "../views/about/Introduce.vue"),
          //     meta: {
          //       title: "单位简介",
          //     },
          //   },
          // ],
        },
        {
          path: "/ljsyy",
          component: () => import( /*市级技术平台 */ "../views/ljsyy/Ljsyy.vue"),
          meta: {
            title: "市级技术平台",
          },
        },

        {
          path: "/exhibition",
          component: () => import( /*项目展示 */ "../views/exhibition/Exhibition.vue"),
          meta: {
            title: "项目展示",
          },
        },
        {
          path: "/exhibition",
          component: () =>
            import(
              /*RouterView容器 */
              "../views/layout/ContainerRouterView.vue"
            ),
          meta: {
            title: "项目展示",
          },
          children: [{
            path: "/exhibition/details",
            component: () =>
              import( /*项目展示详情 */ "../views/exhibition/ExhibitionDetails.vue"),
            meta: {
              title: "项目展示详情",
            },
          }, ]
        },
        {
          path: "/train",
          redirect: "/train/airClass/airClassYbjsfkgykt",
          component: () => import( /*培训教育 */ "../views/train/Train.vue"),
          meta: {
            title: "培训教育",
          },
        },
        {
          path: "/train/airClass/airClassYbjsfkgykt",
          component: () =>
            import( /*SSS */ "../views/train/AirClassYbjsfkgykt.vue"),
          meta: {
            title: "空中课堂 > 渝北近视防控干预空中课堂",
          },
        },
        {
          path: "/user/login",
          component: () =>
            import( /*登录 */ "../views/user/Login.vue"),
          meta: {
            title: "登录",
          },
        },
        {
          path: "/user/wechat",
          component: () =>
            import( /*微信登录回调 */ "../views/user/Wechat.vue"),
          meta: {
            title: "微信登录回调",
          },
        },
        {
          path: "/user/personal",
          component: () =>
            import( /*个人中心 */ "../views/user/Personal.vue"),
          meta: {
            title: "个人中心",
          },
        },
        {
          path: "/user/relation",
          component: () =>
            import( /*关联手机号 */ "../views/user/Relation.vue"),
          meta: {
            title: "关联手机号",
          },
        }
      ],
    }, ],
  },
  {
    path: "/downloadApp",
    component: () => import( /*市级技术平台 */ "../views/ljsyy/download/DownloadApp.vue"),
    meta: {
      title: "两江上游云客户端下载",
    },
  },
  {
    path: "/downloadAppPhone",
    component: () => import( /*市级技术平台 */ "../views/ljsyy/download/DownloadAppPhone.vue"),
    meta: {
      title: "两江上游云客户端Phone下载",
    },
  },
  {
    path: "/downloadUseManual",
    component: () => import( /*市级技术平台 */ "../views/ljsyy/download/DownloadUseManual.vue"),
    meta: {
      title: "重庆市区县融媒体中心市级技术平台使用手册下载",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;