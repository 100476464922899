
import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import router from './router'
import store from './store'
import Moment from "moment";//日期时间
// import axios from './plugins/axios'
import './mock/mock';


createApp(App)
  .use(Antd) 
  .use(store)
  .use(router)
  .use(Moment)
  // .use(axios)
  .mount("#app");
